import React from "react"
import Page from "src/components/page"
import Section from "src/components/section"
import Markup from "src/components/markup"
import PillButton from "src/components/pill-button"
import { useResourcesStore } from "src/state/resources"
import { redCircle, blueCircle } from "src/components/svgs"
import hero1 from "src/images/resources-section-1.png"
import hero3 from "src/images/resources-section-3.png"
import hero4 from "src/images/resources-section-4.png"
import hero5 from "src/images/resources-section-5.png"
import hero6 from "src/images/resources-section-6.png"

const ResourcesPage = () => {
  const { meta, pageTitle, pageBody, section1, section2, section3, section4, section5, section6 } =
    useResourcesStore()

  return (
    <Page {...meta}>
      <Section className="copy-section">
        <h2 className="page-title parallax-3">{pageTitle}</h2>
        <p className="page-body parallax-3">{pageBody}</p>
      </Section>
      <Section className="section-1">
        <div className="left parallax-1a">
          <img className="hero" src={hero1} alt={section1.heroAlt} />
        </div>
        <div className="right parallax-n1a">
          <h3 className="title">
            <Markup>{section1.title}</Markup>
          </h3>
          <p className="body">
            <Markup>{section1.body}</Markup>
          </p>
          <a href={section1.buttonUrl} target="_blank" rel="noreferrer">
            <PillButton className="white-red">{section1.buttonText}</PillButton>
          </a>
        </div>
      </Section>
      <Section className="section-2">
        <div className="copy parallax-2">
          <h3 className="title">
            <Markup>{section2.title}</Markup>
          </h3>
          <p className="body">
            <Markup>{section2.body}</Markup>
          </p>
          <a href={section2.buttonUrl} target="_blank" rel="noreferrer">
            <PillButton className="white-red">{section2.buttonText}</PillButton>
          </a>
        </div>
        <div className="circles parallax-2a">
          <div className="circle-1">
            <div className="bg">
              <Markup>{redCircle}</Markup>
            </div>
            <div className="content">
              <h4>{section2.circle1Title}</h4>
              <a href={section2.circle1ButtonUrl} target="_blank" rel="noreferrer">
                <PillButton className="white-grey">{section2.circle1ButtonText}</PillButton>
              </a>
            </div>
          </div>
          <div className="circle-2">
            <div className="bg">
              <Markup>{blueCircle}</Markup>
            </div>
            <div className="content">
              <h4>{section2.circle2Title}</h4>
              <a href={section2.circle2ButtonUrl} target="_blank" rel="noreferrer">
                <PillButton className="white-red">{section2.circle2ButtonText}</PillButton>
              </a>
            </div>
          </div>
          <div className="circle-3">
            <div className="bg">
              <Markup>{redCircle}</Markup>
            </div>
            <div className="content">
              <h4>{section2.circle3Title}</h4>
              <a href={section2.circle3ButtonUrl} target="_blank" rel="noreferrer">
                <PillButton className="white-grey">{section2.circle3ButtonText}</PillButton>
              </a>
            </div>
          </div>
        </div>
      </Section>
      <Section className="section-3">
        <div className="section-content">
          <div className="left parallax-1a">
            <img className="hero" src={hero3} alt={section3.heroAlt} />
          </div>
          <div className="right parallax-n1a">
            <h3 className="title">
              <Markup>{section3.title}</Markup>
            </h3>
            <p className="body">
              <Markup>{section3.body}</Markup>
            </p>
            <a href={section3.buttonUrl} target="_blank" rel="noreferrer">
              <PillButton className="white-red">{section3.buttonText}</PillButton>
            </a>
          </div>
        </div>
      </Section>
      <Section className="section-4">
        <div className="section-content">
          <div className="left parallax-1a">
            <h3 className="title">
              <Markup>{section4.title}</Markup>
            </h3>
            <p className="body">
              <Markup>{section4.body}</Markup>
            </p>
            <a href={section4.buttonUrl} target="_blank" rel="noreferrer">
              <PillButton className="white-red">{section4.buttonText}</PillButton>
            </a>
          </div>
          <div className="right parallax-n1a">
            <img className="hero" src={hero4} alt={section4.heroAlt} />
          </div>
        </div>
      </Section>
      <Section className="section-5">
        <div className="section-content">
          <div className="left parallax-1a">
            <img className="hero" src={hero5} alt={section5.heroAlt} />
          </div>
          <div className="right parallax-n1a">
            <h3 className="title">
              <Markup>{section5.title}</Markup>
            </h3>
            <p className="body">
              <Markup>{section5.body}</Markup>
            </p>
            <a href={section5.buttonUrl} target="_blank" rel="noreferrer">
              <PillButton className="white-red">{section5.buttonText}</PillButton>
            </a>
          </div>
        </div>
      </Section>
      <Section className="section-6">
        <div className="section-content">
          <div className="left parallax-1a">
            <h3 className="title">
              <Markup>{section6.title}</Markup>
            </h3>
            <p className="body">
              <Markup>{section6.body}</Markup>
            </p>
            <a href={section6.buttonUrl} target="_blank" rel="noreferrer">
              <PillButton className="white-red">{section6.buttonText}</PillButton>
            </a>
          </div>
          <div className="right parallax-n1a">
            <img className="hero" src={hero6} alt={section6.heroAlt} />
          </div>
        </div>
      </Section>
    </Page>
  )
}

export default ResourcesPage
