import create from "zustand"

export const useResourcesStore = create((set) => ({
  meta: {
    id: "resources",
    title: "Resources, Podcast & Guides - The Partnership Economy",
    description:
      "Check out these free resources to help get you started and manage partnerships programs that perform.",
  },
  pageTitle: "Resources to grow your partnerships",
  pageBody:
    "What do you need to thrive in the partnership economy? Check out these resources to help get you started and manage partnerships programs that perform.",
  section1: {
    title: "Manage the partnership life cycle",
    body: "From discovery and onboarding to measurement and commissioning, each phase of the partnership life cycle has specific management requirements and keys to success. Learn about each phase with this interactive infographic.",
    buttonText: "Explore the life cycle",
    buttonUrl:
      "https://impact.com/partnership-cloud/pc-aw-ig-managing-partnerships-throughout-their-life-cycle/",
    heroAlt: "partnerships life-cycle",
  },
  section2: {
    title: "Bundled kits so you can get started now",
    body: "Download partnerships starter packs for worksheets, ebooks, and tactical materials designed to help you kick off a successful program.",
    buttonText: "See all starter packs",
    buttonUrl: "https://impact.com/kits-worksheets/",
    circle1Title: "Influencer partnerships starter pack",
    circle1ButtonText: "Download",
    circle1ButtonUrl: "https://impact.com/kits-worksheets/",
    circle2Title: "Brand-to-brand partnerships starter pack",
    circle2ButtonText: "Download",
    circle2ButtonUrl:
      "https://go.impact.com/2021-Ultimate-brand-to-brand-partnerships-pack.html?_ga%3D2.180727202.422257557.1641316552-1436269855.1628549444&sa=D&source=docs&ust=1642543631381666&usg=AOvVaw3EwA51sx-OqcQQCi_-tyf7",
    circle3Title: "Affiliate partnerships starter pack",
    circle3ButtonText: "Download",
    circle3ButtonUrl: "https://impact.com/kits-worksheets/",
  },
  section3: {
    title: "Keep up — listen to <i>The Partnership Economy</i> podcast",
    body: "Take your partnerships education on the go with candid conversations and stories from industry leaders. Our hosts, David A. Yovanno, CEO, and Todd Crawford, cofounder of impact.com, unpack the future of partnerships.",
    buttonText: "Tune in now",
    buttonUrl: "https://impact.com/podcasts/",
    heroAlt: "the partnership economy podcast",
  },
  section4: {
    title: "Could you be growing a lot faster?",
    body: "<p>Take the partnership maturity quiz, and in just five minutes, benchmark your current partnerships program, assess how you rank on the maturity scale, and identify opportunities to boost return on investment (ROI).<p>",
    buttonText: "Take the quiz",
    buttonUrl: "https://impact.com/partnership-cloud/partnership-assessment/",
    heroAlt: "partnership assessment",
  },
  section5: {
    title: "Prevent fraud in your partnerships program",
    body: "Learn how to identify and purge affiliate and influencer fraud and allocate spend to legitimate partners that deliver value.",
    buttonText: " Learn more",
    buttonUrl: "https://impact.com/protect-monitor/performance-fraud",
    heroAlt: "performance fraud",
  },
  section6: {
    title: "Start a partnerships reading group",
    body: "Fired up about partnerships and want to delve deeper with others? You’ll find hours of inspiration for discussion in <i>The Instructor’s Companion Guide to The Partnership Economy.</i>",
    buttonText: "Download the guide",
    buttonUrl: "/Instructor_s_Companion_Guide_to_The_Partnership_Economy.pdf",
    heroAlt: "download the partnership economy guide",
  },
}))
